import React from 'react';
import { Animated } from 'react-animated-css';
import { Popover, PopoverBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import moment from 'moment';
import _, { slice } from 'lodash';
import fakeAuth from '../../../fakeAuth';
import request from 'superagent';
import config from '../../../config';

class TransactionForm extends React.Component {
    state = {
        mode: 1,
        validations: {},
        transaction: {},
        other: 0,
        IdProperty: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')).IdProperty : null,
    }

    validate = (e) => {
        e && e.preventDefault && e.preventDefault();
        if (this.state.microTransact) return;
        this.setState({ validations: {} }, () => {
            const { validations, transaction } = this.state;

            const { id } = this.props.params, { IdProperty } = this.state;

            if (!this.txtReason.value) {
                validations.txtReason = 'Por favor, introduce el concepto de la operación';
                this.setState({ validations }, () => { this.txtReasonWrapper.scrollIntoView({ behavior: 'smooth' }); });
                return;
            }
            if (!this.txtAmount.value) {
                validations.txtAmount = 'Por favor, introduce el monto de la operación';
                this.setState({ validations }, () => { this.txtAmountWrapper.scrollIntoView({ behavior: 'smooth' }); });
                return;
            }
            if (this.state.mode === 2 && !this.txtConfirmation.value) {
                validations.txtAmount = 'Por favor, introduce el código de confirmación de la operación';
                this.setState({ validations }, () => { this.txtConfirmationWrapper.scrollIntoView({ behavior: 'smooth' }); });
                return;
            }

            this.setState({ microTransact: true }, () => {
                // CALL API                
                request.post(`${config.apiEndpoint}/api/v4/accounting${this.props.auxiliar ? `/auxiliar` : ''}`)
                    .set('auth_token', localStorage.getItem('legendbeds.authtoken'))
                    .send({
                        IdBook: id,
                        IdProperty,
                        IdBookRoom: this.props.auxiliar ? this.props.auxiliar.IdBookRoom : null,
                        Reason: this.txtReason.value,
                        Debit: this.state.mode === 1 ? parseFloat(this.txtAmount.value) : 0,
                        Credit: this.state.mode === 2 ? parseFloat(this.txtAmount.value) : 0,
                        Other: (this.state.IGTF && this.state.mode === 2) ? this.state.other : null,
                        IsRefund: 0,
                        PaymentObject: {},
                        IdPaymentType: (transaction.IdPaymentType || 1),
                        Confirmation: this.state.mode === 2 ? this.txtConfirmation.value : null
                    })
                    .then((data) => {
                        if (data.body.success) {
                            // CALL API to refresh payments
                            this.props.accounting_actions && this.props.accounting_actions.fetch(this.props.params.id);
                            this.props.toggle && this.props.toggle();
                        }
                    }, (error) => {
                        this.setState({ microTransact: false, error: 'Something wrong' });
                        //this.props.setDeveloperMessage && this.props.setDeveloperMessage(error);
                    });
            });

        });
    }

    render() {
        const { mode, validations, transaction } = this.state, { auxiliar } = this.props;
        const { payment_types } = config;
        console.log({ auxiliar });

        return <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1 }}>
            <div className="black-input bg-gray-2 p-4 align-self-center" style={{ width: 400 }}>
                <h3 className="white poppins text-center">Agregar {mode === 1 ? 'Cargo' : 'Pago'}</h3>
                <hr className="w-50 mx-auto" />
                <div className="d-flex">
                    <button onClick={() => this.setState({ mode: 1 })} className={`btn btn-${mode === 1 ? 'blue' : 'link bordered'} white w-100`}>Cargo</button>
                    <button onClick={() => this.setState({ mode: 2 })} className={`btn btn-${mode === 2 ? 'blue' : 'link bordered'} white w-100 bordered ms-2`}>Pago</button>
                </div>
                {/* CONCEPTO */}
                <div className="w-100 mt-4" ref={i => this.txtReasonWrapper = i}>
                    <label className="white poppins font-12">Concepto <i className="blue">*</i></label>
                    <div id="txtReason" className={`black-input ${validations.txtReason ? 'error-input' : ''} d-flex mt-2`}>
                        <input onChange={e => {

                        }} className="w-100 p-3 white" type="text" name="name" placeholder="Concepto de operación" ref={(input) => this.txtReason = input} defaultValue={transaction.Reason} />
                    </div>
                    <Popover placement="right" isOpen={validations.txtReason ? true : false} target={`txtReason`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtReason = !validations.txtReason;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtReason}</span>
                        </PopoverBody>
                    </Popover>
                </div>

                {/* MONTO */}
                <div className="w-100 mt-4" ref={i => this.txtAmountWrapper = i}>
                    <label className="white poppins font-12">Monto <i className="blue">*</i></label>
                    <div id="txtAmount" className={`black-input ${validations.txtAmount ? 'error-input' : ''} d-flex mt-2`}>
                        <i className="fas fa-dollar whiteish font-20 align-self-center ms-3" />
                        <input onChange={e => {
                            if (!_.isNaN(parseFloat(e.target.value))) this.setState({ other: parseFloat(e.target.value) * 0.03 });
                        }} className="w-100 p-3 white" type="text" name="amount" placeholder="Monto" ref={(input) => this.txtAmount = input} defaultValue={transaction.Amount} />
                    </div>
                    <Popover placement="right" isOpen={validations.txtAmount ? true : false} target={`txtAmount`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtAmount = !validations.txtAmount;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtAmount}</span>
                        </PopoverBody>
                    </Popover>
                </div>

                {/* PAYMENT TYPE */}
                {this.state.mode === 2 && <div className="w-100 mt-4" ref={i => this.txtTypeWrapper = i}>
                    <label className="white poppins font-12">Tipo <i className="blue">*</i></label>
                    <div id="txtType" className={`black-input ${validations.txtType ? 'error-input' : ''} d-flex mt-2`}>

                        <Dropdown isOpen={this.state.pickPaymentType} toggle={() => this.setState({ pickPaymentType: !this.state.pickPaymentType })} className="w-100">
                            <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="w-100 p-3 white bg-transparent d-flex">
                                <span className="align-self-center">{(_.find(payment_types, t => parseInt(t.id, 10) === (transaction.IdPaymentType || 1))).name}</span>
                                <i className="fas fa-chevron-down font-16 white align-self-center ps-3 ms-auto" />
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                {payment_types && payment_types.map((c, i) => <DropdownItem key={i} onClick={() => {
                                    const { transaction } = this.state;
                                    transaction.IdPaymentType = parseInt(c.id, 10);
                                    this.setState({ transaction });
                                    const { validations } = this.state;
                                    validations.txtType = false;
                                    this.setState({ validations });
                                }}>{c.name}</DropdownItem>)}
                            </DropdownMenu>
                        </Dropdown>

                    </div>
                    <Popover placement="right" isOpen={validations.txtType ? true : false} target={`txtType`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtType = !validations.txtType;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtType}</span>
                        </PopoverBody>
                    </Popover>
                </div>}

                {/* IGFT */}
                {this.state.mode === 2 && <div className="text-right">
                    <button type="button" onClick={() => {
                        const { IGTF } = this.state;
                        this.setState({ IGTF: !IGTF });
                    }} class="btn btn-link text-right whiteish">Aplica IGTF<i className={`ms-2 ${this.state.IGTF ? 'fas fa-check blue' : 'far fa-square'}`} /></button>
                </div>}
                {/* IGTF CALCULADO */}
                {this.state.mode === 2 && this.state.IGTF && <div className="w-100">
                    <div id="txtOther" className={`black-input ${validations.txtOther ? 'error-input' : ''} d-flex`}>
                        <i className="fas fa-dollar whiteish font-20 align-self-center ms-3" />
                        <input disabled={true} onChange={() => { }} className="w-100 p-3 white" type="text" name="amount" placeholder="Monto" ref={(input) => this.txtIGTF = input} value={this.state.other} />
                    </div>
                </div>}


                {/* CONFIRMATION */}
                {this.state.mode === 2 && <div className="w-100 mt-4" ref={i => this.txtConfirmationWrapper = i}>
                    <label className="white poppins font-12">Confirmación <i className="blue">*</i></label>
                    <div id="txtConfirmation" className={`black-input ${validations.txtConfirmation ? 'error-input' : ''} d-flex mt-2`}>
                        <input onChange={e => {

                        }} className="w-100 p-3 white" type="text" name="confirmation" placeholder="Confirmación" ref={(input) => this.txtConfirmation = input} defaultValue={transaction.Confirmation} />
                    </div>
                    <Popover placement="right" isOpen={validations.txtConfirmation ? true : false} target={`txtConfirmation`} toggle={() => {
                        const { validations } = this.state;
                        validations.txtConfirmation = !validations.txtConfirmation;
                        this.setState({ validations });
                    }} className="bg-gray-2">
                        <PopoverBody className="d-flex flex-row justify-content-center">
                            <i className="fas fa-times red font-8 align-self-center" />
                            <span className="white font-8 poppins align-self-center ms-3" style={{ maxWidth: 100 }}>{validations.txtConfirmation}</span>
                        </PopoverBody>
                    </Popover>
                </div>}



                {/* BUTTONS */}
                <div className="d-flex justify-content-center mt-4">
                    <button onClick={this.props.toggle} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                        <i className="fas fa-times white font-12" />
                    </button>
                    <button onClick={this.validate} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                        {!this.state.microTransact && <i className="fas fa-check white font-12" />}
                        {this.state.microTransact && <i className="fas fa-cog fa-spin white font-12" />}
                    </button>
                </div>
            </div>
        </div >
    }

}

export default TransactionForm;