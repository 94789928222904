import React from 'react';
import { Animated } from 'react-animated-css';
import moment from 'moment';

class WidgetVS extends React.Component {
    state = {
        Flag: 'day'
    }
    componentWillMount = () => {
        this.onFetch();
    }

    onFetch = () => {
        /*const { id } = this.props.params;
        this.props.logic_actions && this.props.logic_actions.clear_occupancy_summary();
        this.props.logic_actions && this.props.logic_actions.fetch_occupancy({ IdProperty: id, Flag: this.state.Flag });*/
    }

    render() {
        const { Flag } = this.state;
        return <Animated animationIn='fadeInRight' animationInDelay={800} className="w-100 bg-gray-2 px-3 py-2 rounded">
            <div className="d-flex">
                <span className="poppins font-12 white align-self-center">Booking vs In.House</span>
                <span className="poppins font-16 gray ms-auto align-self-center"><i className="fas fa-cog fa-spin" /></span>
            </div>
            <div className="d-flex mt-2">
            <span onClick={() => { this.setState({ Flag: 'day' }, this.onFetch); }} className={`pointer poppins font-8 ${Flag === 'day' ? 'blue' : 'white'} align-self-center`}>Dia</span>
                <span onClick={() => { this.setState({ Flag: 'week' }, this.onFetch); }} className={`pointer poppins font-8 ${Flag === 'week' ? 'blue' : 'white'} white align-self-center ms-2`}>Semana</span>
                <span onClick={() => { this.setState({ Flag: 'month' }, this.onFetch); }} className={`pointer poppins font-8 ${Flag === 'month' ? 'blue' : 'white'} white align-self-center ms-2`}>Mes</span>
                {/*<span className="pointer poppins font-8 white align-self-center ms-2">Año</span>*/}
                <i className="icon-download font-16 white ms-auto align-self-center" />
            </div>
        </Animated>
    }
}

export default WidgetVS;