import React from 'react';
import Layout from '../layouts/claire';
import { Animated } from 'react-animated-css';
import Menu from '../partials/menu';
import TopRightMenu from '../partials/top_right_menu';
import LineLoader from '../partials/line_loader';
import { Link } from 'react-router';

import _ from 'lodash';
import fakeAuth from '../../fakeAuth';

class Properties extends React.Component {
    state = {
        menu_toggled: false,
        IDP: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')) : null
    }

    // Lifecycle
    componentWillMount() {
        let user = fakeAuth.getUser();
        if (user.IdProperty) this.props.router.goBack();
        else this.props.properties_actions && this.props.properties_actions.fetch();
    }
    componentWillReceiveProps = nextProps => {

    }
    //

    renderHeader = () => {
        const { IDP } = this.state;
        return <div className="d-flex">
            <div className="w-100 d-flex ">
                <i className="icon-lb_suitcase blue font-40 align-self-center" />
                <span className="white ms-2 font-20 poppins align-self-center">Propiedades</span>
                <Link to="/properties/add" className="btn btn-lg btn-blue white px-4 ms-4 align-self-center d-flex" >
                    Nueva Propiedad <i className="fas fa-plus-circle align-self-center font-10 ms-3" />
                </Link>
                {IDP && IDP.IdProperty && <button onClick={() => this.props.router && this.props.router.goBack()} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                    <i className="icon-corner-down-left white font-12" />
                </button>}
            </div>
            <div className="w-75 align-self-center d-flex">
                <TopRightMenu {...this.props} />
            </div>
        </div>
    }
    renderNo = () => {
        return <Animated animationIn="bounceInUp" style={{ position: 'absolute', top: 130, bottom: 0, left: 0, right: 0 }} className="d-flex flex-column justify-content-center p-4">
            <h1 className="align-self-center gray w3-content text-center poppins">Por lo visto no tienes ninguna propiedad creada.<br />Haz click sobre el boton de "Nueva Propiedad" para empezar.</h1>
        </Animated>
    }
    setProperty = (p) => {
        localStorage.setItem('lb.ip', JSON.stringify(_.pick(p, 'IdProperty', 'Name', 'CheckIn', 'CheckOut', 'LogoUrl')));
    }
    renderProperties = () => {
        const { properties } = this.props;
        let selected = localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')) : null;
        return <section className="mt-4">
            <hr />
            <section className="w3-content">
                {properties && _.sortBy(properties, 'Name').map((p, i) =>
                    <Animated key={i} animationIn="fadeInRight" animationInDelay={i * 75} className="bg-gray-2 py-3 d-flex black-input mb-3">

                        <div className="w-25 d-flex justify-content-center">
                            <div className="contains bg-black d-flex justify-content-center align-self-center" style={{ width: 140, height: 140, borderRadius: 70, background: `url(${p.LogoUrl}) no-repeat center center` }}>
                                {/*<i className="fas fa-camera font-60 gray align-self-center" />*/}
                            </div>
                        </div>
                        <div className="w-100">
                            <div className="d-flex">
                                <Link to={`/properties/edit/${p.IdProperty}`}><h3 className="white d-flex">{p.Name} <i className="far font-12 align-self-center fa-edit ms-4" /></h3></Link>
                                <i className={`${(selected && selected.IdProperty === p.IdProperty) ? 'fas fa-circle green' : 'far fa-circle white'} ms-auto align-self-center me-4 pointer`} onClick={() => {
                                    localStorage.setItem('lb.ip', JSON.stringify(_.pick(p, 'IdProperty', 'Name', 'CheckIn', 'CheckOut', 'LogoUrl')));
                                    this.forceUpdate();
                                }} />
                            </div>
                            <div><i className="fas fa-circle green" /><span className="white poppins ms-2">Activo</span></div>
                            <div style={{ height: 50 }} />
                            <div className="d-flex w-100">
                                <Link onClick={() => this.setProperty(p)} to={`/rooms/${p.IdProperty}`} className="w-100 d-flex flex-column ">
                                    <i className="icon-lb_rooms gray font-24 align-self-center"></i>
                                    <span className="gray align-self-center font-10">Habitaciones</span>
                                </Link>
                                <Link onClick={() => this.setProperty(p)} to={`/floormap/${p.IdProperty}`} className="w-100 d-flex flex-column ">
                                    <i className="far fa-map gray font-24 align-self-center"></i>
                                    <span className="gray align-self-center font-10">Floormap</span>
                                </Link>
                                <Link onClick={() => this.setProperty(p)} to={`/plans/${p.IdProperty}`} className="w-100 d-flex flex-column ">
                                    <i className="icon-lb_planes gray font-24 align-self-center"></i>
                                    <span className="gray align-self-center font-10">Planes</span>
                                </Link>
                                <Link onClick={() => this.setProperty(p)} to={`/staff/${p.IdProperty}`} className="w-100 d-flex flex-column ">
                                    <i className="icon-lb_staff gray font-24 align-self-center"></i>
                                    <span className="gray align-self-center font-10">Staff</span>
                                </Link>
                                <Link onClick={() => this.setProperty(p)} to={`/bookings/${p.IdProperty}`} className="w-100 d-flex flex-column ">
                                    <i className="icon-lb_reservaciones gray font-24 align-self-center"></i>
                                    <span className="gray align-self-center font-10">Reservas</span>
                                </Link>
                                <div className="w-100 d-flex flex-column ">
                                    <i className="icon-lb_reportes gray font-24 align-self-center"></i>
                                    <span className="gray align-self-center font-10">Reportes</span>
                                </div>
                                <Link onClick={() => this.setProperty(p)} to={`/dashboard/${p.IdProperty}`} className="w-100 d-flex flex-column ">
                                    <i className="fas fa-ellipsis-h gray font-24  align-self-center"></i>
                                    <span className="gray align-self-center font-10">Mas</span>
                                </Link>
                                <div className="w-100" />
                                <div className="w-100 ms-auto d-flex flex-column ">
                                    <i className="fas fa-trash gray font-24 align-self-center"></i>
                                </div>
                            </div>
                        </div>

                    </Animated>)}
            </section>
        </section>
    }
    renderCommon = () => {
        const { loading } = this.props;
        return (
            <div className="">
                {loading && <LineLoader />}
                {this.renderHeader()}
                {this.props.properties && this.props.properties.length > 0 && this.renderProperties()}
                {this.props.properties && !this.props.properties.length && this.renderNo()}
                <div style={{ height: 100 }} />
            </div>
        )
    }

    // Render
    render() {
        const form = this.props.form_actions;
        return (
            <Layout router={this.props.router} nbs="home" title="legendbeds" form={this.props.form} form_actions={this.props.form_actions}>
                <section>

                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small " style={{ marginLeft: 0 }}>
                        <div style={{ height: 50 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    {/*<div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
        </div>*/}

                    {/*<Menu {...this.state} {...this.props} />*/}

                </section>
            </Layout>
        )
    }
}

export default Properties;
